import {
  CREATE_NEW_SUB_CATEGORY,
  DELETE_SUB_CATEGORY,
  GET_ALL_SUBCATEGORIES,
  SUB_CATEGORY_LOADING,
  UPDATE_SUBCATEGORY_STATUS,
} from '../action-types';

const initialState = {
  subCategories: [],
  loading: false,
};
export const subCategoryReducers = (state = initialState, action) => {
  switch (action.type) {
    case SUB_CATEGORY_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_SUBCATEGORIES:
      return {
        ...state,
        subCategories: action.payload,
        loading: false,
      };

    case CREATE_NEW_SUB_CATEGORY:
      return {
        ...state,
        subCategories: [...state.subCategories, action.payload],
        loading: false,
      };

    case UPDATE_SUBCATEGORY_STATUS:
      return {
        ...state,
        subCategories: state.subCategories.map((val) =>
          val._id === action.payload._id ? { ...val, ...action.payload } : val
        ),
        loading: false,
      };

    case DELETE_SUB_CATEGORY:
      return {
        ...state,
        subCategories: state.subCategories.filter((val) => val._id !== action.payload._id),
        loading: false,
      };

    default:
      return state;
  }
};
