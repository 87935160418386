import { GET_ALL_PRICING_ENQUIRIES } from '../action-types';

const initialState = {
  pricingEnquiries: [],
  loading: false,
};
export const pricingEnquiriesReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PRICING_ENQUIRIES:
      return {
        ...state,
        pricingEnquiries: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
