import { ADMIN_SIGNIN_SUCCESS, ADMIN_LOADED, ADMIN_LOADING, ADMIN_LOGOUT } from '../action-types';

const initialState = {
  token: sessionStorage.getItem('token'),
  isAuthenticated: null,
  loading: false,
  superAdmin: null,
};

export const authReducers = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADMIN_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        superAdmin: action.payload,
      };
    case ADMIN_SIGNIN_SUCCESS:
      sessionStorage.setItem('token', action.payload.token);
      return {
        ...state,
        token: action.payload.token,
        superAdmin: action.payload.user,
        isAuthenticated: true,
        loading: false,
      };
    case ADMIN_LOGOUT:
      sessionStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenticated: null,
        loading: false,
        superAdmin: null,
      };
    default:
      return state;
  }
};
