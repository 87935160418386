// user api actions type --------------
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_ALL_ADMIN = 'GET_ALL_ADMIN';
export const UPDATE_USER_AUTH_STATUS = 'UPDATE_USER_AUTH_STATUS';
export const UPDATE_USER_OTP_STATUS = 'UPDATE_USER_OTP_STATUS';
export const USER_DATA_STATUS_COUNT = 'USER_DATA_STATUS_COUNT';
export const USER_DATA_COUNT_FOR_MAIL = 'USER_DATA_COUNT_FOR_MAIL';

// admin authentication action type ----------------
export const ADMIN_SIGNIN_SUCCESS = 'ADMIN_SIGNIN_SUCCESS';
export const ADMIN_LOADING = 'ADMIN_LOADING';
export const ADMIN_LOADED = 'ADMIN_LOADED';
export const ADMIN_LOGOUT = 'ADMIN_LOGOUT';

// admin products action type ----------------
export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const GET_LIMITED_PRODUCT = 'GET_LIMITED_PRODUCT';
export const LOADING_PRODUCT_DATA_FALSE = 'LOADING_PRODUCT_DATA_FALSE';
export const PRODUCT_LOADING = 'PRODUCT_LOADING';
export const UPDATE_PRODUCT_STATUS = 'UPDATE_PRODUCT_STATUS';
export const GET_PRODUCT_SEARCH_FILTER = 'GET_PRODUCT_SEARCH_FILTER';
export const GET_SINGLE_PRODUCT_DATA = 'GET_SINGLE_PRODUCT_DATA';

// admin categories action type ----------------
export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES';
export const CREATE_NEW_CATEGORY = 'CREATE_NEW_CATEGORY';
export const UPDATE_CATEGORY_STATUS = 'UPDATE_CATEGORY_STATUS';
export const CATEGORY_LOADING = 'CATEGORY_LOADING';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';

// admin sub-categories action type ----------------
export const GET_ALL_SUBCATEGORIES = 'GET_ALL_SUBCATEGORIES';
export const UPDATE_SUBCATEGORY_STATUS = 'UPDATE_SUBCATEGORY_STATUS';
export const SUB_CATEGORY_LOADING = 'SUB_CATEGORY_LOADING';
export const CREATE_NEW_SUB_CATEGORY = 'CREATE_NEW_SUB_CATEGORY';
export const DELETE_SUB_CATEGORY = 'DELETE_SUB_CATEGORY';

// admin brands action type ----------------
export const GET_ALL_BRANDS = 'GET_ALL_BRANDS';
export const UPDATE_BRAND_STATUS = 'UPDATE_BRAND_STATUS';
export const CREATE_NEW_BRAND = 'CREATE_NEW_BRAND';
export const BRAND_LOADING = 'BRAND_LOADING';
export const DELETE_BRAND = 'DELETE_BRAND';

// admin states action type ----------------
export const GET_ALL_STATES = 'GET_ALL_STATES';
export const STATE_LOADING = 'STATE_LOADING';
export const CREATE_NEW_STATE = 'CREATE_NEW_STATE';

// admin country action type ----------------
export const GET_ALL_COUNTRY = 'GET_ALL_COUNTRY';
export const COUNTRY_LOADING = 'COUNTRY_LOADING';

// admin dist action type ----------------
export const GET_ALL_CITY = 'GET_ALL_CITY';
export const CREATE_NEW_CITY = 'CREATE_NEW_CITY';
export const CITY_LOADING = 'CITY_LOADING';

// admin feedback action type ----------------
export const GET_ALL_FEEDBACK = 'GET_ALL_FEEDBACK';

// admin help action type ----------------
export const GET_ALL_HELP = 'GET_ALL_HELP';

// admin pricing enquiries action type ----------------
export const GET_ALL_PRICING_ENQUIRIES = 'GET_ALL_PRICING_ENQUIRIES';

// admin task action type ----------------
export const GET_ALL_TASK = 'GET_ALL_TASK';
export const UPDATE_TASK_PROGRESS = 'UPDATE_TASK_PROGRESS';
export const UPDATE_TASK_PRIORITY = 'UPDATE_TASK_PRIORITY';
export const CREATE_NEW_TASK = 'CREATE_NEW_TASK';
export const DELETE_TASK = 'DELETE_TASK';

// admin banner action type ----------------
export const GET_ALL_BANNER = 'GET_ALL_BANNER';
export const BANNER_LOADING = 'BANNER_LOADING';
export const DELETE_BANNER = 'DELETE_BANNER';
export const CREATE_BANNER = 'CREATE_BANNER';
export const UPDATE_BANNER_CATEGORY = 'UPDATE_BANNER_CATEGORY';
export const UPDATE_BANNER_STATUS = 'UPDATE_BANNER_STATUS';

// admin dashboard action type ----------------
export const GET_ALL_DASHBOARD_PRODUCT = 'GET_ALL_DASHBOARD_PRODUCT';
export const GET_ALL_DASHBOARD_USERS = 'GET_ALL_DASHBOARD_USERS';
export const GET_ALL_DASHBOARD_STATES = 'GET_ALL_DASHBOARD_STATES';
export const GET_ALL_DASHBOARD_CATEGORIES = 'GET_ALL_DASHBOARD_CATEGORIES';
export const GET_ALL_DASHBOARD_SUB_CATEGORIES = 'GET_ALL_DASHBOARD_SUB_CATEGORIES';
export const GET_ALL_DASHBOARD_FEEDBACKS = 'GET_ALL_DASHBOARD_FEEDBACKS';
export const GET_ALL_DASHBOARD_CITIES = 'GET_ALL_DASHBOARD_CITIES';
export const GET_ALL_DASHBOARD_BRANDS = 'GET_ALL_DASHBOARD_BRANDS';
export const GET_TOTAL_COUNT_OF_CATEGORY_WISE_PRODUCTS = 'GET_TOTAL_COUNT_OF_CATEGORY_WISE_PRODUCTS';
export const GET_TOTAL_COUNT_OF_STATE_WISE_PRODUCTS = 'GET_TOTAL_COUNT_OF_STATE_WISE_PRODUCTS';
export const DASHBOARD_LOADING = 'DASHBOARD_LOADING';
export const GET_DIST_OF_STATE_PRODUCT_COUNT = 'GET_DIST_OF_STATE_PRODUCT_COUNT';
export const GET_TOTAL_COUNT_OF_RATING = 'GET_TOTAL_COUNT_OF_RATING';

// admin dashboard action type ----------------
export const SEND_MARKETING_MAILS = 'SEND_MARKETING_MAILS';
export const LOADING_SEND_MARKETING_MAILS = 'LOADING_SEND_MARKETING_MAILS';
