import {
  GET_ALL_ADMIN,
  GET_ALL_USERS,
  UPDATE_USER_AUTH_STATUS,
  UPDATE_USER_OTP_STATUS,
  USER_DATA_COUNT_FOR_MAIL,
  USER_DATA_STATUS_COUNT,
} from '../action-types';

const initialState = {
  users: [],
  admins: [],
  loading: false,
  userData: {},
};

export const userReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case USER_DATA_STATUS_COUNT:
      return {
        ...state,
        userData: action.payload,
        loading: false,
      };
    case USER_DATA_COUNT_FOR_MAIL:
      return {
        ...state,
        userData: action.payload,
        loading: false,
      };
    case GET_ALL_ADMIN:
      return {
        ...state,
        admins: action.payload,
        loading: false,
      };
    case UPDATE_USER_AUTH_STATUS:
    case UPDATE_USER_OTP_STATUS:
      return {
        ...state,
        users: state.users.map((val) => (val._id === action.payload._id ? { ...val, ...action.payload } : val)),
        loading: false,
      };

    default:
      return state;
  }
};
