import axios from 'axios';
import { SEND_MARKETING_MAILS, LOADING_SEND_MARKETING_MAILS } from '../action-types';

const baseUrl = process.env.REACT_APP_API_ENDPOINT;

export const sendMarketingMails = (payload, setEmailSendStatus) => async (dispatch, getState) => {
  dispatch(setMarketingMailLoding());
  try {
    const config = tokenConfig(getState);

    const { data } = await axios.post(
      `${baseUrl}/marketing_email/admin/send_emails`,
      { startIndex: payload?.startIndex, endIndex: payload?.endIndex },
      config
    );
    dispatch({ type: SEND_MARKETING_MAILS, payload: data });
    setEmailSendStatus({ status: true, message: data?.message });
  } catch (error) {
    console.log(error.message);
  }
};

export const setMarketingMailLoding = () => {
  return {
    type: LOADING_SEND_MARKETING_MAILS,
  };
};

export const tokenConfig = (getState) => {
  const token = getState().auth?.token;
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  if (token) {
    config.headers['x-auth-token'] = token;
  }
  return config;
};
