import {
  CATEGORY_LOADING,
  CREATE_NEW_CATEGORY,
  DELETE_CATEGORY,
  GET_ALL_CATEGORIES,
  UPDATE_CATEGORY_STATUS,
} from '../action-types';

const initialState = {
  categories: [],
  loading: false,
};
export const categoryReducers = (state = initialState, action) => {
  switch (action.type) {
    case CATEGORY_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
        loading: false,
      };

    case CREATE_NEW_CATEGORY:
      return {
        ...state,
        categories: [...state.categories, action.payload],
        loading: false,
      };

    case UPDATE_CATEGORY_STATUS:
      return {
        ...state,
        categories: state.categories.map((val) =>
          val._id === action.payload._id ? { ...val, ...action.payload } : val
        ),
        loading: false,
      };

    case DELETE_CATEGORY:
      return {
        ...state,
        categories: state.categories.filter((val) => val._id !== action.payload._id),
        loading: false,
      };
    default:
      return state;
  }
};
