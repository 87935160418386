import React, { useEffect, useState } from 'react';

// material
import { Stack, Container, Typography, Button, Menu, MenuItem, Snackbar, Alert } from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { sendMarketingMails } from '../redux-thunk/actions/MarketingMailActions';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDataCountForMail } from '../redux-thunk/actions/userActions';
import { LoadingButton } from '@mui/lab';

export default function EmailMarketing() {
  const dispatch = useDispatch();

  const [mailCount, setMailCount] = useState({ startIndex: '', endIndex: '' });
  const [emailSendStatus, setEmailSendStatus] = useState({ status: false, message: '' });

  const usersAllData = useSelector((state) => state?.users);
  const mailData = useSelector((state) => state?.mails);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const totalUsers = usersAllData?.userData?.data;
  const usersPerPage = 10;
  const totalPages = Math.ceil(totalUsers / usersPerPage);

  const handleMenuItemClick = (data) => {
    setMailCount(data);
  };

  const getPageRange = (page) => {
    const start = page * usersPerPage;
    const end = Math.min(start + usersPerPage, totalUsers);
    return [start, end];
  };

  const handleSendMessage = () => {
    dispatch(sendMarketingMails(mailCount, setEmailSendStatus));
  };

  const handleCloseSnackBar = () => {
    setEmailSendStatus({ status: false });
  };

  useEffect(() => {
    dispatch(getUserDataCountForMail());
  }, [dispatch]);

  return (
    <Page title="Email Marketing">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Email Marketing
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-around" mb={5}>
          <Button
            variant="contained"
            className="reseller-button"
            startIcon={<Iconify icon="mdi:card-account-mail" />}
            onClick={handleClick}
            sx={{ width: '200px' }}
          >
            {mailCount.startIndex === '' && mailCount.endIndex === ''
              ? 'Select Mail Count'
              : `${mailCount.startIndex} - ${mailCount.endIndex}`}
          </Button>

          <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
            {Array.from({ length: totalPages }, (_, index) => index).map((page) => {
              const [start, end] = getPageRange(page);

              return (
                <>
                  <MenuItem
                    key={page}
                    value={{ startCount: start + 1, endIndex: end }}
                    onClick={() => {
                      handleMenuItemClick({ startIndex: start + 1, endIndex: end });
                      handleClose();
                    }}
                  >
                    <Typography fontWeight={600} fontSize="1rem">{`${start + 1} - ${end}`}</Typography>
                  </MenuItem>
                </>
              );
            })}
          </Menu>
          <LoadingButton
            loading={mailData?.loading}
            sx={{ my: 1 }}
            startIcon={<Iconify icon="mingcute:send-fill" />}
            autoFocus
            onClick={() => handleSendMessage()}
            variant="contained"
            className="reseller-button"
          >
            Send Mails
          </LoadingButton>
        </Stack>
        <Snackbar open={emailSendStatus?.status} autoHideDuration={2000} onClose={handleCloseSnackBar}>
          <Alert onClose={handleCloseSnackBar} severity="success" variant="filled" sx={{ width: '100%' }}>
            {emailSendStatus?.message}
          </Alert>
        </Snackbar>
      </Container>
    </Page>
  );
}
