import { GET_ALL_HELP } from '../action-types';

const initialState = {
  helps: [],
  loading: false,
};
export const helpReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_HELP:
      return {
        ...state,
        helps: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
