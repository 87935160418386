import { GET_ALL_FEEDBACK } from '../action-types';

const initialState = {
  feedbacks: [],
  loading: false,
};
export const feedbackReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_FEEDBACK:
      return {
        ...state,
        feedbacks: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
