import {
  GET_ALL_PRODUCTS,
  GET_LIMITED_PRODUCT,
  LOADING_PRODUCT_DATA_FALSE,
  PRODUCT_LOADING,
  UPDATE_PRODUCT_STATUS,
  GET_PRODUCT_SEARCH_FILTER,
  GET_SINGLE_PRODUCT_DATA,
} from '../action-types';

const initialState = {
  products: [],
  singleData: [],
  loading: false,
};
export const productReducers = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case LOADING_PRODUCT_DATA_FALSE:
      return {
        ...state,
        loading: false,
      };

    case GET_ALL_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        loading: false,
      };

    case GET_LIMITED_PRODUCT:
      return {
        ...state,
        products: action.payload,
        loading: false,
      };
    case GET_PRODUCT_SEARCH_FILTER:
      return {
        ...state,
        products: action.payload,
        loading: false,
      };

    case UPDATE_PRODUCT_STATUS:
      return {
        ...state,
        products: state.products.map((val) => (val._id === action.payload._id ? { ...val, ...action.payload } : val)),
        loading: false,
      };

    case GET_SINGLE_PRODUCT_DATA:
      return {
        ...state,
        singleData: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};
