import { GET_ALL_BRANDS, UPDATE_BRAND_STATUS, CREATE_NEW_BRAND, BRAND_LOADING, DELETE_BRAND } from '../action-types';

const initialState = {
  brands: [],
  loading: false,
};
export const brandReducers = (state = initialState, action) => {
  switch (action.type) {
    case BRAND_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_BRANDS:
      return {
        ...state,
        brands: action.payload,
        loading: false,
      };

    case CREATE_NEW_BRAND:
      return {
        ...state,
        brands: [...state.brands, action.payload],
        loading: false,
      };

    case UPDATE_BRAND_STATUS:
      return {
        ...state,
        brands: state.brands.map((val) => (val._id === action.payload._id ? { ...val, ...action.payload } : val)),
        loading: false,
      };

    case DELETE_BRAND:
      return {
        ...state,
        brands: state.brands.filter((val) => val._id !== action.payload._id),
        loading: false,
      };

    default:
      return state;
  }
};
