import { combineReducers } from 'redux';

import { authReducers } from './authReducers';
import { productReducers } from './productReducers';
import { categoryReducers } from './categoryReducers';
import { subCategoryReducers } from './subCategoryReducers';
import { brandReducers } from './brandReducers';
import { stateReducers } from './stateReducers';
import { cityReducers } from './cityReducers';
import { feedbackReducers } from './feedbackReducers';
import { userReducers } from './userReducers';
import { helpReducers } from './helpReducers';
import { pricingEnquiriesReducers } from './pricingEnquiriesReducers';
import { taskReducers } from './taskReducers';
import { bannerReducers } from './bannerReducers';
import { dashboardReducers } from './dashboardReducers';
import { marketingMailReducers } from './MarketingMailReducers';
import { countryReducers } from './countryReducers';

export default combineReducers({
  auth: authReducers,
  users: userReducers,
  products: productReducers,
  categories: categoryReducers,
  subCategories: subCategoryReducers,
  states: stateReducers,
  brands: brandReducers,
  cities: cityReducers,
  feedbacks: feedbackReducers,
  helps: helpReducers,
  pricingEnquiries: pricingEnquiriesReducers,
  tasks: taskReducers,
  banners: bannerReducers,
  dashboard: dashboardReducers,
  mails: marketingMailReducers,
  countries: countryReducers,
});
