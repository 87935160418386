import { CREATE_NEW_TASK, GET_ALL_TASK, UPDATE_TASK_PRIORITY, UPDATE_TASK_PROGRESS } from '../action-types';

const initialState = {
  tasks: [],
  loading: false,
};
export const taskReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TASK:
      return {
        ...state,
        tasks: action.payload,
        loading: false,
      };

    case CREATE_NEW_TASK:
      return {
        ...state,
        tasks: [...state.tasks, action.payload],
        loading: false,
      };

    case UPDATE_TASK_PROGRESS:
    case UPDATE_TASK_PRIORITY:
      return {
        ...state,
        tasks: state.tasks.map((val) => (val._id === action.payload._id ? { ...val, ...action.payload } : val)),
      };

    default:
      return state;
  }
};
