import { useEffect, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { loadSuperUser } from './redux-thunk/actions/authActions';
import EmailMarketing from './pages/EmailMarketing';

// layouts
const DashboardLayout = lazy(() => import('./layouts/dashboard'));
// import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
const Blog = lazy(() => import('./pages/Blog'));
const User = lazy(() => import('./pages/User'));
const Login = lazy(() => import('./pages/Login'));
const NotFound = lazy(() => import('./pages/Page404'));
const Products = lazy(() => import('./pages/Products'));
const DashboardApp = lazy(() => import('./pages/DashboardApp'));
const Brand = lazy(() => import('./pages/Brand'));
const Category = lazy(() => import('./pages/Category'));
const SubCategory = lazy(() => import('./pages/SubCategory'));
const State = lazy(() => import('./pages/State'));
const City = lazy(() => import('./pages/City'));
const FeedBack = lazy(() => import('./pages/FeedBack'));
const Help = lazy(() => import('./pages/Help'));
const PricingEnquiries = lazy(() => import('./pages/PricingEnquiries'));
const Task = lazy(() => import('./pages/Task'));
const Banner = lazy(() => import('./pages/Banner'));

// ----------------------------------------------------------------------

export default function Router() {
  const dispatch = useDispatch();
  const AdminAuth = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(loadSuperUser());
  }, [dispatch]);

  return useRoutes([
    {
      path: AdminAuth?.token ? '/dashboard' : '/',
      element: AdminAuth?.token ? <DashboardLayout /> : <Login />,
      children: [
        {
          path: AdminAuth?.token ? '/dashboard' : '/',
          element: <Navigate to={AdminAuth?.token ? '/dashboard/app' : '/login'} />,
        },
        { path: AdminAuth?.token ? 'app' : 'login', element: AdminAuth?.token ? <DashboardApp /> : <Login /> },
        { path: 'users', element: <User /> },
        { path: 'brands', element: <Brand /> },
        { path: 'categories', element: <Category /> },
        { path: 'sub-categories', element: <SubCategory /> },
        { path: 'states', element: <State /> },
        { path: 'cities', element: <City /> },
        { path: 'feedback', element: <FeedBack /> },
        { path: 'help', element: <Help /> },
        { path: 'task', element: <Task /> },
        { path: 'banner', element: <Banner /> },
        { path: 'pricing-enquiries', element: <PricingEnquiries /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
        { path: 'login', element: <Login /> },
        { path: 'email_marketing', element: <EmailMarketing /> },
        { path: '404', element: <NotFound AdminAuth={AdminAuth} /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to={AdminAuth?.token ? '/dashboard/404' : '/404'} replace /> },

    // { path: 'register', element: <Register /> },

    // {
    //   path: '/dashboard',
    //   element: <DashboardLayout />,
    //   children: [
    //     { path: 'app', element: <DashboardApp /> },
    //     { path: 'user', element: <User /> },
    //     { path: 'products', element: <Products /> },
    //     { path: 'blog', element: <Blog /> },
    //   ],
    // },
    // {
    //   path: '/',
    //   element: <LogoOnlyLayout />,
    //   children: [
    //     { path: '/', element: <Navigate to="/dashboard/app" /> },
    //     { path: 'login', element: <Login /> },
    //     { path: 'register', element: <Register /> },
    //     { path: '404', element: <NotFound /> },
    //     { path: '*', element: <Navigate to="/404" /> },
    //   ],
    // },
  ]);
}
