import {
  BANNER_LOADING,
  CREATE_BANNER,
  GET_ALL_BANNER,
  UPDATE_BANNER_CATEGORY,
  UPDATE_BANNER_STATUS,
} from '../action-types';

const initialState = {
  banners: [],
  loading: false,
};
export const bannerReducers = (state = initialState, action) => {
  switch (action.type) {
    case BANNER_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_BANNER:
      return {
        ...state,
        banners: action.payload,
        loading: false,
      };

    case CREATE_BANNER:
      return {
        ...state,
        banners: [...state.banners, action.payload],
        loading: false,
      };

    case UPDATE_BANNER_STATUS:
    case UPDATE_BANNER_CATEGORY:
      return {
        ...state,
        banners: state.banners.map((val) => (val._id === action.payload._id ? { ...val, ...action.payload } : val)),
        loading: false,
      };

    default:
      return state;
  }
};
