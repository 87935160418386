import axios from 'axios';
import { ADMIN_LOADED, ADMIN_LOADING, ADMIN_LOGOUT, ADMIN_SIGNIN_SUCCESS } from '../action-types';

const baseUrl = process.env.REACT_APP_API_ENDPOINT;

export const loadSuperUser = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ADMIN_LOADING });

    const config = tokenConfig(getState);

    const { data } = await axios.get(`${baseUrl}/auth/admin/data`, config);
    dispatch({ type: ADMIN_LOADED, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const AdminLoginAction =
  ({ email, password }) =>
  async (dispatch) => {
    try {
      const config = { headers: { 'Content-Type': 'application/json' } };
      const { data } = await axios.post(`${baseUrl}/auth/admin`, { email, password }, config);
      dispatch({ type: ADMIN_SIGNIN_SUCCESS, payload: data });
      window.location.pathname = '/dashboard/app';
    } catch (error) {
      console.log(error.response.data.msg);
    }
  };

export const adminLogout = () => () => {
  window.location.pathname = '/login';
  sessionStorage.removeItem('token');

  return {
    type: ADMIN_LOGOUT,
  };
};

export const tokenConfig = (getState) => {
  const token = getState().auth?.token;
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  if (token) {
    config.headers['x-auth-token'] = token;
  }
  return config;
};
