import {
  GET_ALL_DASHBOARD_PRODUCT,
  GET_ALL_DASHBOARD_BRANDS,
  GET_ALL_DASHBOARD_CATEGORIES,
  GET_ALL_DASHBOARD_CITIES,
  GET_ALL_DASHBOARD_FEEDBACKS,
  GET_ALL_DASHBOARD_STATES,
  GET_ALL_DASHBOARD_SUB_CATEGORIES,
  GET_ALL_DASHBOARD_USERS,
  GET_TOTAL_COUNT_OF_CATEGORY_WISE_PRODUCTS,
  GET_TOTAL_COUNT_OF_STATE_WISE_PRODUCTS,
  GET_DIST_OF_STATE_PRODUCT_COUNT,
  GET_TOTAL_COUNT_OF_RATING,
} from '../action-types';

const initialState = {
  productsCount: '',
  usersCount: '',
  categoriesCount: '',
  subCategoriesCount: '',
  brandsCount: '',
  feedbacksCount: '',
  statesCount: '',
  citiesCount: '',
  totalCountOfCategoryWiseProducts: {},
  totalCountOfStateWiseProducts: {},
  totalCountForDistOfStateProducts: {},
  totalRatingAverageCount: '',
  loading: false,
};
export const dashboardReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_DASHBOARD_PRODUCT:
      return {
        ...state,
        productsCount: action.payload,
        loading: false,
      };
    case GET_ALL_DASHBOARD_BRANDS:
      return {
        ...state,
        brandsCount: action.payload,
        loading: false,
      };
    case GET_ALL_DASHBOARD_CATEGORIES:
      return {
        ...state,
        categoriesCount: action.payload,
        loading: false,
      };
    case GET_ALL_DASHBOARD_SUB_CATEGORIES:
      return {
        ...state,
        subCategoriesCount: action.payload,
        loading: false,
      };
    case GET_ALL_DASHBOARD_CITIES:
      return {
        ...state,
        citiesCount: action.payload,
        loading: false,
      };
    case GET_ALL_DASHBOARD_FEEDBACKS:
      return {
        ...state,
        feedbacksCount: action.payload,
        loading: false,
      };
    case GET_ALL_DASHBOARD_USERS:
      return {
        ...state,
        usersCount: action.payload,
        loading: false,
      };
    case GET_ALL_DASHBOARD_STATES:
      return {
        ...state,
        statesCount: action.payload,
        loading: false,
      };
    case GET_TOTAL_COUNT_OF_CATEGORY_WISE_PRODUCTS:
      return {
        ...state,
        totalCountOfCategoryWiseProducts: action?.payload,
        loading: false,
      };
    case GET_TOTAL_COUNT_OF_STATE_WISE_PRODUCTS:
      return {
        ...state,
        totalCountOfStateWiseProducts: action?.payload,
        loading: false,
      };
    case GET_DIST_OF_STATE_PRODUCT_COUNT:
      return {
        ...state,
        totalCountForDistOfStateProducts: action?.payload,
        loading: false,
      };
    case GET_TOTAL_COUNT_OF_RATING:
      return {
        ...state,
        totalRatingAverageCount: action?.payload,
        loading: false,
      };
    default:
      return state;
  }
};
