import { LOADING_SEND_MARKETING_MAILS, SEND_MARKETING_MAILS } from '../action-types';

const initialState = {
  mailsStatusMessage: {},
  loading: false,
};
export const marketingMailReducers = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_SEND_MARKETING_MAILS:
      return {
        ...state,
        loading: true,
      };

    case SEND_MARKETING_MAILS:
      return {
        ...state,
        mailsStatusMessage: action.payload.message,
        loading: false,
      };
    default:
      return state;
  }
};
