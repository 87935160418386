import axios from 'axios';
import {
  GET_ALL_USERS,
  UPDATE_USER_AUTH_STATUS,
  UPDATE_USER_OTP_STATUS,
  GET_ALL_ADMIN,
  USER_DATA_STATUS_COUNT,
  USER_DATA_COUNT_FOR_MAIL,
} from '../action-types';

const baseUrl = process.env.REACT_APP_API_ENDPOINT;

export const getAllUsers = () => async (dispatch, getState) => {
  try {
    const config = tokenConfig(getState);
    const { data } = await axios.get(`${baseUrl}/user`, config);
    dispatch({
      type: GET_ALL_USERS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateUserAuthStatus = (id, updateStatus) => async (dispatch, getState) => {
  try {
    const config = tokenConfig(getState);
    await axios.put(`${baseUrl}/user/auth_status/${id}`, { auth_status: updateStatus }, config);

    dispatch({ type: UPDATE_USER_AUTH_STATUS, payload: { _id: id, auth_status: updateStatus } });
  } catch (error) {
    console.log(error.message);
  }
};

export const updateUserOtpStatus = (id, updateStatus) => async (dispatch, getState) => {
  try {
    const config = tokenConfig(getState);
    await axios.put(`${baseUrl}/user/auth_status/${id}`, { isVerified: updateStatus }, config);

    dispatch({ type: UPDATE_USER_OTP_STATUS, payload: { _id: id, isVerified: updateStatus } });
  } catch (error) {
    console.log(error.message);
  }
};

export const getAllAdmin = () => async (dispatch, getState) => {
  try {
    const config = tokenConfig(getState);
    const { data } = await axios.get(`${baseUrl}/user/admin/all`, config);
    dispatch({
      type: GET_ALL_ADMIN,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};
export const getUserDataCount = () => async (dispatch, getState) => {
  try {
    const config = tokenConfig(getState);
    const { data } = await axios.get(`${baseUrl}/user/admin/user_data_count`, config);
    dispatch({
      type: USER_DATA_STATUS_COUNT,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};
export const getUserDataCountForMail = () => async (dispatch, getState) => {
  try {
    const config = tokenConfig(getState);
    const { data } = await axios.get(`${baseUrl}/user/admin/mail_user_data_count/`, config);
    dispatch({
      type: USER_DATA_COUNT_FOR_MAIL,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const tokenConfig = (getState) => {
  const token = getState().auth?.token;
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  if (token) {
    config.headers['x-auth-token'] = token;
  }
  return config;
};
